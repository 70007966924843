import React, { useState } from "react";
import "./header.scss";
import { Offcanvas } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <section className="main-navbar">
      <div className="parenthead">
          <div className="parenthead2">
          <Link to="/">
            <img className="mylogo" src="\assets\logo.svg"/></Link>
            <p className="leftborder"></p>
            <p className="help">Help Center</p>
          </div>
          <div>
            <a href="https://tomi.com/browser" target="blank" className="downlod">Download tomi Browser</a>
          </div>
        </div>
      </section>



     
    </>
  );
};

export default Header;
