import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './components/landing/Landing';
import './App.scss'; // Import your CSS file here
import Getting from './components/landing/Getting/Getting';


function App() {

  return (
    <>

    
        <Router>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/howcanwehelp" element={<Getting />} />
          </Routes>
        </Router>
    
    </>
  );
}

export default App;
