import React, { useState } from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState("0");
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <section className="mainbanner8">
        <div className="mainparent8">

          <div className="parentbar111">
            <p className="para687">Frequently Asked Questions</p>
            <h1 className="para444444">HERE Are The ANSWERs to YOUR QUESTIONS</h1>
            <div className="mainfaqs">
              <div className="innerfaq">
                <Accordion activeKey={activeKey} onSelect={handleSelect}>
                  <Accordion.Item
                    eventKey="0"
                    className={activeKey === "0" ? "active" : ""}
                  >
                    <Accordion.Header className="headerrrrrr">
                      <p>
                        <span>01.</span>What is Tomi Browser?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Tomi Browser is a Chromium-based web browser that allows users to seamlessly switch between the traditional Web 2 internet and the decentralized Web 3 internet, providing enhanced privacy, security, and freedom from censorship.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    className={activeKey === "1" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>02. </span>How do I download and install Tomi Browser?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Visit the Tomi Browser download page, select your operating system (Windows, macOS, Linux), and follow the on-screen instructions to download and install the browser.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className={activeKey === "2" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>03.</span>How do I switch between Web 2 and Web 3?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p> Click the Tomi logo button in the browser to toggle between Web 2 (traditional internet) and Web 3 (Tomi's blockchain-based internet).
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="3"
                    className={activeKey === "3" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>04.</span>How do I add a bookmark in Tomi Browser?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Click the star icon in the address bar or press Ctrl + D (Windows) or Cmd + D (Mac). You can name your bookmark and choose a folder to save it in.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="4"
                    className={activeKey === "4" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>05.</span>How do I enable private browsing mode?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p> Open a private window by clicking the menu button and selecting 'New Incognito Window', or by pressing Ctrl + Shift + N (Windows) or Cmd + Shift + N (Mac).
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
