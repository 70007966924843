import '../../App.scss';
import React, { useEffect } from "react";
import Header from './Header/Header';
import Banner from './Banner/Banner';
import WOW from "wowjs";
import Footer from './Footer/Footer';
import Mission from './Mission/Mission';
import Faqs from './Faqs/Faqs';
import Getting from './Getting/Getting';



function Landing() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="mainlandang">
        <Header />
        <Banner />
        <Mission/>
        <Faqs/>
        <Footer />
      </div>
    </>
  );
}

export default Landing;
    