import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
    <section className="main-footer ">
        <div className="container paddrightzero">
          <div className="row">
            <div className="col-xl-12 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-12 col-12 p-0 m-auto">
                  <div className="row">
                    <div className="col-xl-2   col-sm-12 padd-sm p-0 ">
                      <div className="info">
                        <Link to="/">
                          <img
                            className="logoss"
                            src="\assets\logo2.svg"
                          ></img>
                        </Link>
                        {/* <p>
                          Fresh and convenient: Come for Groceries,
                          <br /> Stay for Experience!
                        </p> */}
                      </div>
                    </div>
                    <div className="col-xl-2   col-sm-6 col-4  ">
                      <div className="line ">
                        <h4>Solutions</h4>
                        <div className="list">
                          <Link to="/browser" target="_blank">
                            <p>Browser</p>
                          </Link>

                          <a href="https://domains.tomi.com/" target="_blank">
                            <p>Domains</p>
                          </a>

                          {/* <p>Help Center</p> */}

                          <Link to="/wallet" target="_blank">
                            <p>Wallet</p>
                          </Link>

                          <a href="https://dao.tomi.com/" target="_blank">
                            <p>DAO</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2  col-sm-6 col-4 ">
                      <div className="line">
                        <h4>Community</h4>
                        <div className="list">
                          <a
                            href="https://twitter.com/tomipioneers"
                            target="_blank"
                          >
                            <p>X</p>
                          </a>

                          <a href="https://t.me/tomipioneers" target="_blank">
                            {" "}
                            <p>TG announcement </p>
                          </a>
                          <a
                            class="scoialimg"
                            href="https://t.me/tomi_official_chat"
                            target="_blank"
                          >
                            {" "}
                            <p>TG group</p>
                          </a>

                          <a
                            href="https://discord.com/invite/tomipioneers"
                            target="_blank"
                          >
                            <p>Discord</p>
                          </a>

                          <a
                            href="https://www.reddit.com/r/tomipioneers/?rdt=51853"
                            target="_blank"
                          >
                            {" "}
                            <p>Reddit</p>
                          </a>
                          <a href="https://tomiarmy.com/" target="_blank">
                            <p>tomiArmy</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2   col-sm-6  col-4 newonlybormarginnn">
                      <div className="line">
                        <h4>Education</h4>
                        <div className="list">
                          <Link to="/news" target="_blank">
                            <p>blog</p>
                          </Link>

                          <a
                            href="https://medium.com/tomipioneers"
                            target="_blank"
                          >
                            {" "}
                            <p>medium</p>
                          </a>
                          <a
                            href="https://www.youtube.com/@tomipioneers"
                            target="_blank"
                          >
                            {" "}
                            <p>YouTube</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  col-sm-12">
                      <div className="gradientborder">
                        <div className="linelast">
                          <div className="listsss">
                            <p>
                              <a href="mailto:tomiteam@proton.me">
                                <img
                                  className="my-2"
                                  src="\assets\mail.svg"
                                />
                              </a>
                            </p>
                            <h4 className="leeefftter">Email Us</h4>
                            <div className="errowdiv">
                            <p className="lefttlastpara">
                              Send your questions or suggestions securely via
                              our encrypted email for prompt support and
                              feedback. 
                            </p>
                            <img className="imgarrow" src="\assets\errrow.svg"/>
                            </div>
                            
                            
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-12 m-auto p-0">
                  <hr className="toplineonly"></hr>
                  <div className="lastdddsdfcc">
                    <p className="lastbutmm">
                      © 2024 tomi, all rights reserved.
                    </p>
                    {/* <Link to="/privacy">
                      <p className="lastbutmm">Terms of use | Privacy policy</p>
                    </Link> */}
                    <p className="lastbutmm">
                      {" "}
                      <Link to="/useragreement" className="lastbutmm" target="_blank">
                        Terms of use
                      </Link>{" "}
                      |{" "}
                      <Link to="/privacy" className="lastbutmm" target="_blank">
                        Privacy policy
                      </Link>
                    </p>
                    {/* <div className="privacytexts">
                        <p className="termtext"><Link to="/useragreement">Terms of use</Link>  |  <Link to="/privacy">Privacy policy</Link></p>
                        <p className="rightstext">© 2024  TOMI, All Rights Reserved</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
