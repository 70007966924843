import React, { useState, useEffect, useRef } from "react";
import "./banner.scss";
import axios from "axios";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate, useLocation } from "react-router-dom";

const Banner = () => {
  const [search, setSearch] = useState("");
  const [helpData, setHelpData] = useState(null);
  const [filteredHelpData, setFilteredHelpData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axios.get('/helpData.json')
      .then(response => {
        console.log('Data', response?.data?.sections);
        setHelpData(response.data?.sections);
        setFilteredHelpData(response.data?.sections);
      })
      .catch(error => {
        console.error('Error loading the help data:', error);
      });
  }, []);

  useEffect(() => {
    if (helpData) {
      const filteredSections = helpData.map(section => {
        const filteredSubsections = section.subsections?.map(subsection => {
          const filteredSteps = subsection.steps?.filter(step =>
            step.toLowerCase().includes(search.toLowerCase())
          );

          if (
            subsection.title.toLowerCase().includes(search.toLowerCase()) ||
            (filteredSteps && filteredSteps.length > 0)
          ) {
            return {
              ...subsection,
              steps: filteredSteps,
            };
          }

          return null;
        }).filter(subsection => subsection !== null);

        if (
          section.title.toLowerCase().includes(search.toLowerCase()) ||
          filteredSubsections?.length > 0
        ) {
          return {
            ...section,
            subsections: filteredSubsections,
          };
        }

        return null;
      }).filter(section => section !== null);

      setFilteredHelpData(filteredSections);
      setDropdownOpen(search !== "" && (filteredSections.length > 0 || filteredSections.length === 0));
    }
  }, [search, helpData]);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setDropdownOpen(searchTerm !== "" && (filteredHelpData?.length > 0 || filteredHelpData?.length === 0));
  };

  const handleInputFocus = () => {
    if (search !== "") {
      setDropdownOpen(true);
    }
  };

  const handleInputBlur = () => {
    // Prevent closing the dropdown immediately on blur
    setTimeout(() => {
      if (searchInputRef.current && !searchInputRef.current.contains(document.activeElement)) {
        setDropdownOpen(false);
      }
    }, 200);
  };

  const handleDropdownItemClick = (id) => {
    if (location.pathname === '/howcanwehelp') {
      navigate(`/howcanwehelp?id=${id}`, { replace: true });
      window.location.reload();
    } else {
      navigate(`/howcanwehelp?id=${id}`);
    }
  };

  return (
    <>
      <section className="mainbanner">
        <div className="mainparent">
          <div className="headerdiv">
            <Dropdown show={dropdownOpen}>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="inputhelp"
                onClick={() => searchInputRef.current.focus()}
              >
                <input
                  ref={searchInputRef}
                  type="text"
                  id="fname"
                  name="fname"
                  value={search}
                  onChange={handleSearchInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="How can we help?"
                  className="inputhelp22"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu show={dropdownOpen && search !== ""}>
                {filteredHelpData?.length > 0 ? (
                  filteredHelpData.map((section, sectionIndex) => (
                    <div key={sectionIndex}>
                      {section.title.toLowerCase().includes(search.toLowerCase()) && (
                        <Dropdown.Item className="paraaa3" onClick={() => handleDropdownItemClick(section.id)}>
                          <p className="paraaa3">{section?.title}</p>
                        </Dropdown.Item>
                      )}
                      {section.subsections?.map((subsection, subsectionIndex) => (
                        <div key={subsectionIndex} className="pl-4">
                          {subsection.title.toLowerCase().includes(search.toLowerCase()) && (
                            <Dropdown.Item className="paraaa3" onClick={() => handleDropdownItemClick(section.id)}>
                              <p className="paraaa3">{subsection?.title}</p>
                            </Dropdown.Item>
                          )}
                          {subsection.steps?.map((step, stepIndex) => (
                            <div key={stepIndex} className="pl-4">
                              {step.toLowerCase().includes(search.toLowerCase()) && (
                                <Dropdown.Item className="paraaa3" onClick={() => handleDropdownItemClick(section.id)}>
                                  <p className="paraaa3">{step}</p>
                                </Dropdown.Item>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <Dropdown.Item className="paraaa3">No matching results found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Link to={search?.length > 0 ? "/howcanwehelp" : "#"}>
              <button className="pinkbutton">Search</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
