import React, { useState, useEffect } from "react";
import "./getting.scss";
import Header from "../Header/Header";
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import Nav from 'react-bootstrap/Nav';

const Getting = () => {
  const [activeTab, setActiveTab] = useState("1"); // Updated initial activeTab state

  useEffect(() => {
    const val = new URL(window.location.href);
    const id = val.searchParams.get('id');
    setActiveTab(id ? id : '1');
  }, []);

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <>
      <Header />
      <Banner />
      <section className="mainbanner9">
        <div className="mainparent0">
          <div className="headparentttt">
            <div className="maintablepillsty">
              <Nav variant="pills" activeKey={activeTab} onSelect={handleSelect} className="amberpillsouter_leader">
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="1">
                    Getting Started
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="2">
                    Navigating the Browser
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="3">
                    Tomi Button
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="4">
                    Bookmarks and History
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="5">
                    Security and Privacy
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="6">
                    Ad Blocker
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="7">
                    Advanced Settings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="8">
                    Troubleshooting
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div>
              {activeTab === "1" && (
                <div>
                  <h1 className="head111">Getting Started</h1>
                  <p className="para101"><span className="para102">01.</span> Downloading and Installing Tomi Browser</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Go to the Tomi Browser download page.</li>
                      <li className="para104">Choose your operating system (Windows, macOS, Linux) and click the download button.</li>
                      <li className="para104">Follow the installation instructions specific to your OS.</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">02.</span> First-time Setup</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Open the Tomi Browser.</li>
                      <li className="para104">Customize your browser settings by following the setup wizard.</li>
                      <li className="para104">Import bookmarks and settings from other browsers if desired.</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "2" && (
                <div>
                  <h1 className="head111">Navigating the Browser</h1>
                  <p className="para101"><span className="para102">01.</span> User Interface Overview</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Address Bar: Enter URLs or search terms.</li>
                      <li className="para104">Tabs: Open multiple websites in separate tabs.</li>
                      <li className="para104">Menu Button: Access settings, history, downloads, and more.</li>
                      <li className="para104">Tomi Logo Button: Switch between Web 2 and Web 3.</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">02.</span> Using Tabs</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Open a New Tab: Click the plus (+) icon next to the last tab or press Ctrl + T (Windows) or Cmd + T (Mac).</li>
                      <li className="para104">Close a Tab: Click the 'X' on the tab or press Ctrl + W (Windows) or Cmd + W (Mac).</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "3" && (
                <div>
                  <h1 className="head111">Switching Between Web 2 and Web 3</h1>
                  <p className="para101"><span className="para102">01.</span> Tomi Logo Button</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Web 2 Mode: Traditional internet mode.</li>
                      <li className="para104">Web 3 Mode: Access the blockchain-based Tomi network.</li>
                      <li className="para104">Click the Tomi logo button to toggle between Web 2 and Web 3 modes.</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "4" && (
                <div>
                  <h1 className="head111">Managing Bookmarks and History</h1>
                  <p className="para101"><span className="para102">01.</span> Adding Bookmarks</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Click the star icon in the address bar or press Ctrl + D (Windows) or Cmd + D (Mac).</li>
                      <li className="para104">Name your bookmark and choose where to save it.</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">02.</span> Viewing and Managing History</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Click the menu button and select 'History'.</li>
                      <li className="para104">Clear browsing history by clicking 'Clear browsing data'.</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "5" && (
                <div>
                  <h1 className="head111">Security and Privacy Features</h1>
                  <p className="para101"><span className="para102">01.</span> Private Browsing Mode</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Open a private window by clicking the menu button and selecting 'New Incognito Window' or pressing Ctrl + Shift + N (Windows) or Cmd + Shift + N (Mac).</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">02.</span> Privacy Settings</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Access privacy settings through the menu button 'Settings' 'Privacy and security'.</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">03.</span> Security Features</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Built-in phishing and malware protection.</li>
                      <li className="para104">Option to enable HTTPS-Only mode.</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "6" && (
                <div>
                  <h1 className="head111">Ad Blocker</h1>
                  <div className="para5555">
                    <ul>
                      <li className="para104">The Tomi Browser blocks ads and trackers by default, ensuring a more private browsing experience.</li>
                      <li className="para104">This feature is designed to enhance user privacy and provide a seamless, ad-free browsing environment.</li>
                      <li className="para104">By blocking intrusive ads and trackers, users can enjoy faster page load times and reduced data consumption.</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "7" && (
                <div>
                  <h1 className="head111">Advanced Settings</h1>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Manage extensions through the menu button 'Extensions'.</li>
                      <li className="para104">Browse and install extensions from the Web Store.</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">02.</span> Developer Tools</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Open developer tools by pressing Ctrl + Shift + I (Windows) or Cmd + Option + I (Mac).</li>
                    </ul>
                  </div>
                </div>
              )}
              {activeTab === "8" && (
                <div>
                  <h1 className="head111">Troubleshooting</h1>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Browser crashes or freezes: Restart the browser and clear cache.</li>
                      <li className="para104">Pages not loading: Check your internet connection and disable conflicting extensions.</li>
                    </ul>
                  </div>
                  <p className="para101"><span className="para102">02.</span> Support</p>
                  <div className="para5555">
                    <ul>
                      <li className="para104">Visit our Support Page for more help.</li>
                      <li className="para104">Contact us via Email or Live Chat.</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Getting;
